require('normalize.css')
require('./src/styles/global.scss')

exports.shouldUpdateScroll = (params) => {
	const { prevRouterProps, routerProps } = params

	// Dont update scroll position if moving between blog pages
	if (prevRouterProps && routerProps) {
		if (prevRouterProps.location.pathname.includes('/blog') && routerProps.location.pathname.includes('/blog/')) {
			return false
		}
	} else {
		window.scrollTo(0, 0)
		const body = document.getElementsByTagName('body')[0]
		body.scrollTop = 0

		return false
	}
}
