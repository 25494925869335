module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FinanceFinity","short_name":"FinanceFinity","start_url":"/","background_color":"color","theme_color":"color","display":"minimal-ui","icon":"src/images/icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.financefinity.co.uk/graphql","html":{"useGatsbyImage":true,"imageQuality":100,"createStaticFiles":true,"generateWebpImages":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateAvifImages":false,"placeholderType":"dominantColor"},"verbose":false,"searchAndReplace":[{"search":"https://admin.financefinity.co.uk","replace":"https://financefinity.co.uk"}],"production":{"allow404Images":true,"allow401Images":true,"hardCacheMediaFiles":false},"schema":{"perPage":100,"requestConcurrency":100,"previewRequestConcurrency":5,"timeout":2000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"type":{"Comment":{"exclude":true},"ContentType":{"exclude":true},"PostFormat":{"exclude":true},"UserRole":{"exclude":true},"User":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"thg0uhb"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MCV65W7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
